import { useContext } from 'react'
import { UiContext } from 'library/context/uiContext'
import { isEmpty, formatDate, grammaticalJoin } from 'library/utility/global'

const SpotlightCard = ({ entry, type }) => {

  // Get UI context
  const uiContext = useContext(UiContext)

  // Show article preview
  const handleShowEntry = () => {
    uiContext.openPreview(entry)
  }

  // Get authors
  const authorNames = entry.author && !isEmpty(entry.author) ? entry.author.map(author => author.displayName) : []

  // Build featured image
  let featuredImage
  if (entry.featuredImage && entry.featuredImage.file && entry.featuredImage.file.url) {
    featuredImage = (      
     <div className="spotlight__featured-image" onClick={handleShowEntry}>
        <img
          src={`${entry.featuredImage.file.url}?w=140&h=210&fit=thumb`}
          srcSet={`${entry.featuredImage.file.url}?w=140&h=210&fit=thumb 1x, ${entry.featuredImage.file.url}?w=480&h=400&fit=thumb 2x`}
          width="140"
          height="210"
          title={entry.featuredImage.title}
          alt={entry.featuredImage.title}
          onClick={entry => handleShowEntry()}
          className="excerpt__featured-image"
        />
      </div>
    )
  }

  return (
    <div className="spotlight__card">
      <div className="spotlight__excerpt">
        <div className="spotlight__byline">
          <h3 className="spotlight__title" onClick={handleShowEntry}>{entry.title && entry.title}</h3>
          {/* {!isEmpty(authorNames) && <div className="spotlight__author">{grammaticalJoin(authorNames)}</div>} */}
        </div>
        {featuredImage}
      </div>
      <div className="spotlight__meta">
        {(entry[type] && entry[type][0].title) && <div className="spotlight__meta--title">{entry[type][0].title}</div>}
        {entry.postDate && <div className="spotlight__meta--postdate">{formatDate(entry.postDate, 'F j, Y')}</div>}
      </div>
    </div>
  )
}

export default SpotlightCard;
