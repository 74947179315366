/**
 * Get spotlight/pinned items from contentful
 */

import { useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { isEmpty } from 'library/utility/global'
import axios from 'axios'
import useSWR from 'swr'

import SpotlightCard from 'components/partials/spotlight-card'
import Message from 'components/ui/message'
import Spinner from 'components/ui/spinner'

/**
 * Get spotlighted items
 * @param  {str} options.source   subscription or website
 * @return {null}
 */
const Spotlight = ({ url, params }) => {

  // Auth function from user state
  const { userHasAccess } = useContext(UserContext)

  // Dynamically get content
  const fetcher = url => axios.get(url, {
    params: params
  }).then(res => res.data)

  // Use SWR to asynchronously fetch data for spotlight items
  const { data, error } = useSWR(url, fetcher, { revalidateOnMount: true })

  // Set display var
  let items

  // On Error
  if (error) items = <Message type="warning">Spotlight items failed to load.</Message>

  // Loading
  if (!data) items = <Spinner />

  // On success
  if (data) {

    // Get spotlight items
    let spotlightEntries = []
    for (let row of data) {
      if (row.featureContent && row.featureContent.entry) {
        spotlightEntries = [...spotlightEntries, ...row.featureContent.entry]
      }
    }

    // Build cards
    items = []
    for (let entry of spotlightEntries) {
      switch(entry.cfType) {
        case 'article':
          if (userHasAccess(entry.subscription, ['subscriptionView'])) {
            items.push(
              <SpotlightCard 
                entry={entry} 
                key={entry.cfId}
                type='subscription'
              />
            )
          }
          break
        case 'post':
          items.push(
            <SpotlightCard
              entry={entry}
              key={entry.cfId}
              type='newsletter'
            />
          )
          break
      }
    }

    // Display error of no items accessible/available
    if (isEmpty(items)) items = <Message type="warning">No Spotlight items currently available.</Message>
  }

  return (
    <>
      <p className="spotlight__intro">Recently published and important reports, issues, and things you haven’t read yet.</p>
      <div className="spotlight">
        {items}
      </div>
    </>
  )
}

export default Spotlight