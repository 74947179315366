import { useState, useEffect, useCallback, useContext } from 'react'
import { UiContext } from 'library/context/uiContext'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { isEmpty } from 'library/utility/global'
import axios from 'axios'

import Message from 'components/ui/message'
import Spinner from 'components/ui/spinner'

/**
 * Generates an infinite scrolling list of components that are provided
 * Expects components to accept common API response for rendering
 * @param  {obj} options.element
 * @param  {str} options.url
 * @param  {obj} options.params
 * @param  {str} options.message
 * @return {null}
 */
const InfinteScroll = ({ element, url = '/api/article', params = {}, message = 'No items to load.' }) => {

  // Check if preview is visible to remove ref while visible to prevent constant retrieval
  const { isPreviewVisible } = useContext(UiContext)

  // Disable infinite scroll if preview is enabled
  useEffect(() => {
    setDisabled(isPreviewVisible)
  }, [isPreviewVisible])

  // URL and element required
  if (element === undefined) return <Message type="warning">No component provided.</Message>

  // Set component state
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [items, setItems] = useState([])
  const [hasNextPage, setHasNextPage] = useState(true)

  // Request new items from api
  const loadItems = useCallback((skip = 0) => {

    // Append skip parameters for infinite loop and append pagination if not provided
    const newParams = {
      include: 2, // Default depth
      ...params,
      skip: skip,
      limit: params.limit && params.limit || process.env.NEXT_PUBLIC_INFINITE_SCROLL_COUNT,
    }

    axios.get(url, {
      params: newParams
    }).then(response => {
      if (isEmpty(response.data)) {
        setHasNextPage(false)
      } else {
        let newItems = []
        for (const item of response.data) {
          newItems.push(item)
        }
        setItems(prevItems => prevItems.concat(newItems));
      }
      setLoading(false)
    }).catch(error => {
      setDisabled(true)
    })

  }, [])

  // Callback to handle scroll trigger
  const handleLoadMore = () => {
    setLoading(true)
    loadItems(items.length)
  }

  // Infinite scroll hook
  // https://github.com/onderonur/react-infinite-scroll-hook
  const [infiniteRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    disabled: disabled,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
    delayInMs: 200
  })

  // Build array of items to render based off element provided
  let elements = []
  for (let i = 0; i < items.length; i++) {
    let temp = { ...element }
    temp.props = { data: items[i] }
    temp.key = items[i].cfId
    elements.push(temp)
  }

  return (
    <>
      <div className="infinite-scroll">
        {elements}
        {loading && <Spinner />}
        {!hasNextPage && <Message type="warning">{message}</Message>}
        <div className="infinite-scroll__trigger" ref={infiniteRef} ></div>
      </div>
    </>
  )
}

export default InfinteScroll