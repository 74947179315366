import { useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { UiContext } from 'library/context/uiContext'
import { getExcerpt } from 'library/utility/global'

import ArticleMetaAlt from 'components/article/article-meta-alt'
import ArticleType from 'components/article/article-type'
import StockRecomendations from 'components/entry/stock-recommendations'

const ArticleExcerptAlt = ({ data }) => {

  // Get context elements
  const { userHasAccess } = useContext(UserContext)
  const uiContext = useContext(UiContext)

  // Show article preview
  const handleShowArticle = () => {
    uiContext.openPreview(data)
  }

  // Build stock recommendations
  let stockRecommendations
  if (data && data.stockRecommendations) {
    const subType = data.subType ? { subType: data.subType } : {}
    const hasAccess = userHasAccess(data.subscription, ['subscriptionView'], subType)
    if (hasAccess) stockRecommendations = <StockRecomendations data={data.stockRecommendations} inline={true} />
  }

  return (
    <div className="excerpt excerpt--article-alt">
      <ArticleType data={data} />
      <h3 className="excerpt__title" onClick={handleShowArticle} dangerouslySetInnerHTML={{ __html: data.title }} />
      <div className="excerpt__meta">
        <ArticleMetaAlt data={data} />
      </div>
      <p className="excerpt__preview">
        {data.excerpt && data.excerpt || getExcerpt(data.content)}
      </p>
      {stockRecommendations}
      <p className="excerpt__more">
        <button
          className="button button--ghost wide"
          onClick={data => handleShowArticle()}
        >
          Read More
        </button>
      </p>
    </div>
  )
}

export default ArticleExcerptAlt