import Link from 'next/link'
import { isEmpty, formatDate, grammaticalJoin } from 'library/utility/global'
import { filterSubNavLinkData } from 'library/filters/navigation'
import { getPrimarySubscription } from 'library/utility/content'

const ArticleMetaAlt = ({ data }) => {

  // Get authors
  const authorNames = data.author && !isEmpty(data.author) ? data.author.map(author => author.displayName) : []

  // Get user's first owned subscription
  let primarySubscription = null
  if (data.subscription) {
    primarySubscription = getPrimarySubscription(data.subscription)
  }

  // Set primary category - always first of array
  const primaryCategory = data.articleCategory && !isEmpty(data.articleCategory[0]) ? data.articleCategory[0] : null

  // Use subscription editor for labeling purposes (always use first as "primary")
  const editorSlug = (data.subscription && data.subscription[0].author && data.subscription[0].author.slug) && data.subscription[0].author.slug

  let categoryLabel
  if (primarySubscription && primaryCategory) {
    categoryLabel = <div className="entry-meta__category">{primaryCategory.title}</div>
  }
  // Build product title link (always use first as "primary")
  let productLabel
  if (primarySubscription && primarySubscription.title) {

    // Filter links
    const linkHref = filterSubNavLinkData(`/subscription/${primarySubscription.itemNumber.toLowerCase()}`, primarySubscription)
    
    productLabel = (
      <Link href={linkHref} className="entry-meta__product">
        {primarySubscription.title}
      </Link>
    )
  }

  return (
    <div className={`entry-meta entry-meta--article-alt editor-${editorSlug}`}>
      {productLabel}
      {data.postDate && <div className="entry-meta__postdate">{formatDate(data.postDate, 'F j, Y')}</div>}
      {!isEmpty(authorNames) && <div className="entry-meta__author">{grammaticalJoin(authorNames)}</div>}
    </div>
  )
}

export default ArticleMetaAlt